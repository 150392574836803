export const getRazorPaySettings = (paymentHandler,prefill)=>{
    return {
        key: "rzp_live_4o9BPPPWSadiKB",
        name: "MaatriUpchaar",
        description: "Appointment Fees",
        image: "https://maatriupchaar.com/assets/img/logo.png",
        handler: paymentHandler,
        prefill: prefill,
        notes: {
          address: "2nd Floor,M Square,Thana Road,Sitamarhi-843302",
        },
        theme: {
          color: "#3399cc",
        },
      };
}
