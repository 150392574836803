import { useEffect,useState } from "react";
import { getTestimonialsApi } from "../network/apiCalls";

const Testimonials = () => {
  const [list,setList] = useState([])
  useEffect(() => {
    fetchTestimonials()
  }, []);
  async function fetchTestimonials(){
    try{
      let res = await getTestimonialsApi()
      console.log(res.data.data)
      setList(res.data.data)
    }catch(e){
      console.log(e.message)
    }
  }

  return (
    <>
      <section>
        <div className="container text-center mt-5">
          <div className="section-title">
            <h2>Testimonials</h2>
          </div>
          <div
            id="carouselExampleInterval"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
                {list.map((item,key)=>{
                    return(
                         <div key={key} className={"carousel-item " + (key== 0 ? 'active':'')} data-bs-interval={1000*(key+1)}>
                            <div className="row">
                            <div className="col-sm-8 offset-sm-2">
                                <figure className="text-center">
                                <blockquote className="blockquote">
                                    <p>{item.comment}</p>
                                </blockquote>
                                <figcaption className="blockquote-footer">
                                    {" "}
                                    <cite title="Source Title">{item.author}</cite>
                                </figcaption>
                                </figure>
                            </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
