import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-4 footer-contact">
                <img src={"/assets/img/logo.png"} style={{ height: 85 }} />
                <img
                  src={"/assets/img/dgk.png"}
                  style={{ height: 65, padding: 6 }}
                />
                <p>
                  DGK HealthCare Pvt Ltd
                  <br />
                  2nd Floor,M Square, <br />
                  Thana Road,Sitamarhi-843302
                  <br />
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+919229695061" style={{ color: "inherit" }}>
                    +91 92296 95061
                  </a>
                  <br />
                  <strong>Email:</strong>{" "}
                  <a
                    href="mailto:maatriupchaar.dgkhc@gmail.com"
                    style={{ color: "inherit" }}
                  >
                    maatriupchaar.dgkhc@gmail.com
                  </a>
                  <br />
                </p>
              </div>
              <div
                className="col-12 col-sm-6 col-md-4  footer-links"
                style={{
                  display: window?.customConfig?.sisWebsites ? "block" : "none",
                }}
              >
                <h4>Other Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href={window?.customConfig.orthoURL ?? "#"}>
                      Need Orthopedics Consultation
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col  footer-links">
                <h4>Our Social Networks</h4>
                <div className="social-links mt-3">
                  <a href={window?.customConfig.facebook ?? '#'} className="facebook">
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href={window?.customConfig.instagram ?? '#'} className="instagram">
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a href={window?.customConfig.linkedin ?? '#'} className="linkedin">
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
                <div className="mt-5 d-flex footer-policy">
                  <Link to={"privacy-policy"}>Privacy Policy</Link>
                  <Link to={"refund-policy"}>Refund Policy</Link>
                  <Link to={"terms-and-conditions"}>Terms & Conditions</Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <p className="mb-0 text-center">
                  Copyright &copy; Maatri Upchaar 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
