import axios from 'axios';
import { useEffect, useState } from 'react';
import { ZIM } from 'zego-zim-web';
import { BASEURL, getAppointmentTokenApi } from '../network/apiCalls';

import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    ConversationHeader,
  } from "@chatscope/chat-ui-kit-react"
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
var appID = 1004185203; 
ZIM.create({ appID });
var zim = ZIM.getInstance();

var doctorID = 'MUDoctor';
var conversationType = 0;
var config = {
    nextMessage: null,
    count: 30,
    reverse: true
}
const AppointmentChatRoom = ({booking,token}) => {
    var userInfo = { userID: 'MUPatient', userName: booking.name };
    const [messages,setMessages] = useState([]) 
    const [isConnected,setIsConnected] = useState(false);
    useEffect(()=>{
        loginUser();
        setUpChatRoom();
    },[])

    function setUpChatRoom(){
        // Set up and listen for the callback for receiving error codes. 
        zim.on('error', function (zim, errorInfo) {
            console.log('some error', errorInfo.code, errorInfo.message);
        });

        // Set up and listen for the callback for connection status changes.
        zim.on('connectionStateChanged', function (zim, { state, event, extendedData }) {
            console.log('connectionStateChanged', state, event, extendedData);
            // When SDK logout occurred due to a long-time network disconnection, you will need to log in again. 
            // if (state == 0 && event == 3) {
            //     zim.login(userInfo, token)
            // }
        });

        // Set up and listen for the callback for receiving one-to-one messages. 
        zim.on('receivePeerMessage', function (zim, { messageList, fromConversationID }) {
            console.log('receivePeerMessage', messageList, fromConversationID);
            getMessageHistory()
        });

        // Set up and listen for the callback for token expires.
        zim.on('tokenWillExpire', function (zim, { second }) {
            console.log('tokenWillExpire', second);
            // You can call the renewToken method to renew the token. 
            // To generate a new Token, refer to the Prerequisites.
            zim.renewToken(token)
                .then(function({ token }){
                    // Renewed successfully.
                })
                .catch(function(err){
                    // Renew failed.
                })
        });
        zim.on('receiveRoomMessage', function (zim, { messageList, fromConversationID }) {
            console.log(messageList, fromConversationID);
        });
    }

    function loginUser(){
        console.log("login with token:",token)
        zim.login(userInfo, token)
            .then(function () {
                console.log("User login success")
                zim.deleteAllConversationMessages({isAlsoDeleteServerMessage: true})
                .then(function () {
                    setIsConnected(true);
                    getMessageHistory()
                })
                .catch(function (err) {
                    console.log("deleting error",err)
                })               
            })
            .catch(function (err) {
                console.log("User login failed",err)
                // Login failed.
            });
    }
    function send_messsage(text){
        
        console.log("sending start")
        var toConversationID = doctorID; // Peer user's ID. 
        var conversationType = 0; // Conversation type, 1-on-1 chat: 0. In-room chat: 1. Group chat: 2. 
        var config = { 
            priority: 1, // Set message priority. Low: 1 (by default). Medium: 2. High: 3. 
        };

        var messageTextObj = { type: 1, message: text};
        var notification = {
            onMessageAttached: function(message) {
                // todo: Loading
                console.log("meesage attached:",message)
            }
        }
        zim.sendMessage(messageTextObj, toConversationID, conversationType, config, notification)
        .then(function ({ message }) {
            // c
            console.log("send success",message)
            getMessageHistory()
        })
        .catch(function (err) {
            // Failed to send message.
            console.log("send failed",err)
        });
    }
    var lastSender =''
    if(isConnected == false)
        return <p className='text-center'>Loading...</p>
    return (
        <>
            <div style={{ position: "relative", height: "500px" }}>
                <MainContainer>
                    <ChatContainer>
                        <ConversationHeader>
                            <Avatar
                            name="Dr. Ayushi Agrawal"
                            src={BASEURL+'/panel_assets/dist/img/doctor.svg'}
                        />
                            <ConversationHeader.Content
                                info="MBBS, MS (Gold Medal), Gynaecologist & Obstetrician"
                                userName={"Dr. Ayushi Agrawal"}
                            />
                        </ConversationHeader>
                        <MessageList >
                           {messages.length > 0 && messages.map((msg,index)=>{
                            let avtar = <Avatar 
                            key={index}
                                        name={msg.senderUserID}
                                        size="md"
                                        src={BASEURL+'/panel_assets/dist/img/'+(msg.senderUserID == 'MUDoctor' ? 'doctor.svg':'woman.svg')}
                                    />
                            if(lastSender!=msg.senderUserID){
                                lastSender = msg.senderUserID
                            }else avtar = null
                            return  <Message
                                        key={index}
                                        model={{
                                            message: msg.message,
                                            sentTime: "just now",
                                            sender:msg.senderUserID,
                                            direction: (msg.senderUserID == 'MUDoctor' ?'incoming':'outgoing'),
                                            position: 'normal',
                                        }}
                                        children={[avtar,<Message.Header key={index} sender={msg.senderUserID}/>]}
                                        avatarSpacer={avtar ? false : true}
                                    />
                           })}
                        </MessageList>
                        <MessageInput placeholder="Type message here" attachButton={false} onSend={send_messsage} />
                    </ChatContainer>
                </MainContainer>
            </div>
        </>
    )
    function getMessageHistory(){
        zim.queryHistoryMessage(doctorID, conversationType, config).then(({messageList})=>{
            console.log("messageHIstory",messageList)
            setMessages(messageList)
        });
    }
}

export default AppointmentChatRoom;