import { Route, Routes } from "react-router-dom";
import ConcernSection from "../components/ConcernSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HeroBanner from "../components/HeroBanner";
import IntroSection from "../components/IntroSection";
import PageNotFound from "./PageNotFound";
import Testimonials from "./Testimonials";
import GalleryItems from "../components/GalleryItems";
import MediaPage from "./MediaPage";
import PregnancyRelated from "./forms/PregnancyRelated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "../components/ScrollToTop";
import {SettingContext} from '../App.js'
import { useContext } from "react";
import LoadingOverlay from "../components/LoadingOverlay.js";
import FertilityRelated from "./forms/FertilityRelated.js";
import PeriodRelated from "./forms/PeriodRelated.js";
import ContraceptionRelated from "./forms/ContraceptionRelated.js";
import ExternalRelated from "./forms/ExternalRelated.js";
import SexualityRelated from "./forms/SexualityRelated.js";
import OtherRelated from "./forms/OtherRelated.js";
import PaymentPage from "./PaymentPage.js";
import BookingSuccess from "./BookingSuccess.js";
import AppointmentRoom from "./AppointmentRoom.js";
import AppointmentChatRoom from "./AppointmentChatRoom.js";
import AppointmentEntry from "./AppointmenEntry.js";
import PrivacyPolicy from "./PrivacyPolicy.js";
import TermsAndConditions from "./TermsAndConditions.js";
import RefundPolicy from "./RefundPolicy.js";
const MainSite = () => {
  const context = useContext(SettingContext)
  
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HeroBanner />} />
      </Routes>

      <main id="main">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <IntroSection />
                <ConcernSection />
                <GalleryItems/>
                <FAQSection />
              </>
            }
          />
          <Route
            path="/testimonials"
            element={<Testimonials/>}
          />
           <Route
            path="/media/:type"
            element={<MediaPage/>}
          />
          <Route path="/appointment">
            <Route path="pregnancy-related" element={<PregnancyRelated/>} />
            <Route path="fertility-related" element={<FertilityRelated/>} />
            <Route path="period-related" element={<PeriodRelated/>} />
            <Route path="contraception-related" element={<ContraceptionRelated/>} />
            <Route path="external-related" element={<ExternalRelated/>} />
            <Route path="sexuality-related" element={<SexualityRelated/>} />
            <Route path="other-related" element={<OtherRelated/>} />
          </Route>
          <Route
            path="/booking-success" 
            element={<BookingSuccess/>}
          />
          <Route
            path="/payment" 
            element={<PaymentPage/>}
          />
          <Route
            path="/appointment-entry" 
            element={<AppointmentEntry/>}
          />
          <Route
            path="/appointment-room" 
            element={<AppointmentRoom/>}
          />
          <Route
            path="/appointment-chat-room" 
            element={<AppointmentChatRoom/>}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy/>}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions/>}
          />
          <Route
            path="/refund-policy"
            element={<RefundPolicy/>}
          />
          <Route
            path="*"
            element={<PageNotFound/>}
          />
        </Routes>
      </main>
      <Footer />
      <ToastContainer />
      <ScrollToTop/>
      <LoadingOverlay loading={context.view.loading}/>
    </>
  );
};

export default MainSite;
