import { useContext, useEffect, useState } from "react";
import Select from 'react-select'
import { SettingContext } from "../App";
const PersonalDetailsBox =({handlePayButton,handleUpdate,details})=>{
  const {settings} = useContext(SettingContext)
  const slots = settings.time_slots.map((item)=>{return {label:item.label,value:item.value}})
  const fees = {'text':settings.fees_text,'audio':settings.fees_audio,'video':settings.fees_video}
  useEffect(()=>{
    handleUpdate({key:'payment_app',value:'razorpay'});
  },[])
    return (
        <>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Name *
                  </label>
                  <div className="col-sm-10">
                    <input type="text" name="name" className="form-control" onChange={handleUpdate} placeholder="Your name" />
                  </div>
            </div>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Phone *
                  </label>
                  <div className="col-sm-10">
                    <input type="number" name="phone" className="form-control" onChange={handleUpdate} placeholder="Mobile Number "/>
                  </div>
            </div>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Email *
                  </label>
                  <div className="col-sm-10">
                    <input type="email" name="email" className="form-control" onChange={handleUpdate} placeholder="Email"/>
                  </div>
            </div>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Mode Of Consultation *
                  </label>
                  <div className="col-sm-10">
                  <Select
                    onChange={(selected)=>{handleUpdate({key:'mode',value:selected.value})}}
                    options={[
                      { value: 'text', label: 'Text Consultation' },
                      { value: 'audio', label: 'Audio Consultation' },
                      { value: 'video', label: 'Video Consultation' },
                    ]}
                  />
                  {details && details?.mode && (<PricingDetails mode={details.mode} />)}
                  </div>
            </div>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Prefered Date & Time *
                  </label>
                  <div className="col-sm-10">
                      <div className="input-group mb-3">
                        <span className="input-group-text">Date</span>
                        <input type="date" name="slot_date" className="form-control" onChange={handleUpdate}/>
                        <span className="input-group-text">Time</span>
                        <Select
                        className="form-control"
                          onChange={(selected)=>{handleUpdate({key:'slot_time',value:selected.value})}}
                          options={slots}
                        />
                        
                      </div>
                  </div>
            </div>
            <div className="mb-3 row">
                  <label className="col-sm-2 col-form-label">
                    Payment Mode*
                  </label>
                  <div className="col-sm-10 d-flex gap-4">
                     <img className={"payment-icon " + (details?.payment_app == 'razorpay'?'active':'')} src="/assets/img/razorpay.png" onClick={()=>handleUpdate({key:'payment_app',value:'razorpay'})} />
                     <img className={"payment-icon " + (details?.payment_app == 'paypal'?'active':'')} src="/assets/img/paypal.png" onClick={()=>handleUpdate({key:'payment_app',value:'paypal'})}/>
                  </div>
            </div>
            <div className="mb-3 row">
                 <div className="col-sm-10 pricing">
                    <button type="button" className="buy-btn" onClick={handlePayButton}>Pay Appointment Fees: {details?.mode ? fees[details.mode] : 0} INR</button>
                </div>
            </div>
        </>
    )
}

const PricingDetails = ({mode}) => {
  const {settings} = useContext(SettingContext)
  if(mode == 'text'){
    return <div className="alert alert-primary mt-2"><strong>Note: </strong>
       Limited to 10 minutes of discussion with answering upto approx. 5 questions. Recommended only for resolving queries. Fees: ₹{settings.fees_text}/- with No Follow up and Prescription will not be issued.
      </div>
  }else if(mode =='video'){
    return <div className="alert alert-primary mt-2"><strong>Note: </strong>
   Limited to 10 minutes of Consultation. Recommended for limited diagnosis, treatment & counselling.      Fees: ₹{settings.fees_video}/-. Prescription will be issued. One FREE Follow Up via WhatsApp within 7 days.
    </div>
  }else if(mode == 'audio'){
    return <div className="alert alert-primary mt-2"><strong>Note: </strong>
    Limited to 7 minutes of discussion. Recommended for suggestions,second opinion & counselling. Fees: ₹{settings.fees_audio}/- Prescription will be issued.One FREE Follow Up via WhatsApp within 7 days.
    </div>
  }else{
    return null;
  }
}

export default PersonalDetailsBox


export const validatePersonal = (personalDetails) => {
  if (
    !personalDetails.name ||
    !personalDetails.phone ||
    !personalDetails.mode ||
    !personalDetails.slot_date ||
    !personalDetails.slot_time ||
    !personalDetails.payment_app
  ) {
    return false;
  }
  return true;
} 