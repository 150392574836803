import { Link } from "react-router-dom"

const ConcernBox = ({ title,bgImage,path}) => {
    return (
        <div
          className="mt-4 col-xl-3 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <Link to={'appointment/'+path} className="w-100">
          <div className="icon-box w-100 text-center bg-none p-0" style={{
              backgroundImage:
                `url(${bgImage})`,
              height:145,
              backgroundSize:'100% auto'
            }}>
              <div className="text-box">
                <h4 className="text-white">
                {title}
              </h4>
              </div>
            
          </div>
          </Link>
        </div>
    );
  };
export default ConcernBox