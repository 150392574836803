import { useCallback ,useEffect} from "react";
import useRazorpay from "react-razorpay"
const PaymentPage = () => {
    const [Razorpay, isLoaded] = useRazorpay();

    const handlePayment = useCallback(async () => {
      //const order = await createOrder(params);
  
      const options  = {
        key: "rzp_test_a1wtqingU9ol7p",
        name: "MatrUpchar",
        description: "Appointment Fees",
        image: "http://localhost:3000/assets/img/logo.png",
        order_id: 'order_O5DxCGnmhSNikm',
        handler: (res) => {
          console.log("payment Result",res);
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
  
      const rzpay = new Razorpay(options);
      rzpay.open();
    }, [Razorpay]);
  
    useEffect(() => {
      if (isLoaded) {
        handlePayment();
      }
    }, [isLoaded, handlePayment])
    return (
        <div className="App">
          <button onClick={handlePayment}>Click</button>
        </div>
      );
}
export default PaymentPage