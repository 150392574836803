const PainScale = ({handleScale,painValue}) =>{ 
    return (
        <div className="d-flex pain-container flex-wrap">
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(1)} className={"pain-item " + (painValue == 1 ?'active-pain':'')} style={{backgroundPosition:'0px 0px'}}></div>
                <label>No Pain</label>
            </div>
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(2)} className={"pain-item " + (painValue == 2 ?'active-pain':'')}  style={{backgroundPosition:'-52px 0px'}}></div>
                <label>Mild Pain</label>
            </div>
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(3)} className={"pain-item " + (painValue == 3 ?'active-pain':'')}  style={{backgroundPosition:'-103px 0px'}}></div>
                <label>Moderate Pain</label>
            </div>
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(4)} className={"pain-item " + (painValue == 4 ?'active-pain':'')}  style={{backgroundPosition:'-154px 0px'}}></div>
                <label>Severe Pain</label>
            </div>
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(5)} className={"pain-item " + (painValue == 5 ?'active-pain':'')}  style={{backgroundPosition:'-209px 0px'}}></div>
                <label>Very Severe Pain</label>
            </div>
            <div className="m-2 d-flex flex-column align-items-center">
                <div onClick={()=>handleScale(6)} className={"pain-item " + (painValue == 6 ?'active-pain':'')}  style={{backgroundPosition:'45px 0px'}}></div>
                <label>Worst Pain</label>
            </div>
        </div>
    )
}

export default PainScale