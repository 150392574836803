export const concernOptions = {
    "pregnancy": {
        title:"Pregnancy Related",
        bgImage:'/assets/img/baby.png',
        path:'pregnancy-related'
      },
    "period": {
        title:"Periods Related",
        bgImage:'/assets/img/sanitary-napkin.png',
        path:'period-related'
      },
    "fertility": {
        title:"Fertility Related",
        bgImage:'https://static.euronews.com/articles/stories/07/66/54/20/1200x675_cmsv2_8705f35e-c039-5990-a862-f152e6d61a06-7665420.jpg',
        path:'fertility-related'
      },
    "contraception":{
        title:"Contraception",
        bgImage:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXbxa_mKlKxGUinhXhvU5h8tqZq0p3h0AWFcxCwS0Qjw&s',
        path:'contraception-related'
      } ,
    "sexuality": {
        title:"Sexuality Related",
        bgImage:'/assets/img/couple.png',
        path:'sexuality-related'
      },
    "external":{
        title:"External Genital Related",
        bgImage:'https://onlinedoctor.lloydspharmacy.com/blob/122080/dcd3c325dae732123d7a50921da5abcc/common-vaginal-problems---picture-data.jpg',
        path:'external-related'
      },
    "other":{
        title:"Others",
        bgImage:'https://previews.123rf.com/images/vectomart/vectomart1210/vectomart121000038/15632192-illustration-of-lady-doctor-with-stethoscope-on-medical-background.jpg',
        path:'other-related'
      }
};
export function getConcern(type){
    return concernOptions[type]
}
export function getConcernTypeByPath(path){
    let res = false
    for(let key of Object.keys(concernOptions)){
        if(concernOptions[key].path == path){
            res = key
            break;
        }
    }
    return res;
}