import ConcernBox from "../components/ConcernBox";
import { concernOptions } from "../utils/ConcernOptions";
const ConcernSection = () => {
    const concerns = Object.keys(concernOptions).map((opKey)=>concernOptions[opKey])
    return (
        <>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>What's Your Concern?</h2>
            </div>

            <div className="row">
              {concerns.map((item, key) => {
                return <ConcernBox key={key} path={item.path} title={item.title} bgImage={item.bgImage} />;
              })}
            </div>
          </div>
        </section>
        </>
    );
}

export default ConcernSection