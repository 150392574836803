import { useContext, useState } from "react"
import FormComplaintBox, { complaintTemplate } from "../../components/FormComplaintBox"
import { toast } from "react-toastify"
import PersonalDetailsBox, { validatePersonal } from "../../components/PersonalDetailsBox"
import { bookingRequestApi } from "../../network/apiCalls"
import { useLocation, useNavigate } from "react-router-dom"
import { getConcern, getConcernTypeByPath } from "../../utils/ConcernOptions"
import { SettingContext } from "../../App"
import { LMPField, YourComplaintLabel, fieldSetter, formDataGenerate } from "../../components/CommonInputs"
import useRazorpay from "react-razorpay"
import { getRazorPaySettings } from "../../utils/PaymentSettings"
const PregnancyRelated = () => {
    const context = useContext(SettingContext)
    const location = useLocation()
    const navigate = useNavigate()
    const concernType = getConcernTypeByPath(location.pathname.split('/').pop())
    const [partOne,setPartOne] = useState(false)
    const [fields,setFields] = useState({})
    const [personalDetails,setPersonalDetails] = useState({})
    const [complaints,setComplaints] = useState(complaintTemplate);
    const [Razorpay] = useRazorpay();

    const updateField = fieldSetter(fields,setFields)
    const updatePersonalField = fieldSetter(personalDetails,setPersonalDetails)

    function completePartOne() {
      if(validateForm('partOne')){
        setPartOne(true)
      }
    }
    async function submitForm() {
      if(concernType == false){
        toast.error("Something wrong! Please reload page and try again")
        return false;
      }
      if(validateForm('partOne') && validateForm('partTwo')){
        try{
          let formData = formDataGenerate(fields,personalDetails,complaints,concernType);
          context.setView({...context.view,loading:true})
          let res = await bookingRequestApi(formData)
          context.setView({...context.view,loading:false})
          if(res.data.status == 200){
            if(res.data.data.payment_app == 'razorpay'){
              const responseHandler = (paymentResponse)=>{
                toast.success("Payment Successful")
                navigate("/booking-success?id="+res.data.data.booking_code)
              }      
              const options = getRazorPaySettings(responseHandler,{
                name:personalDetails.name,
                contact:personalDetails.phone,
                email:personalDetails.email
              })
              options.order_id = res.data.data.order_id
              console.log("payment settings",options)
              const rzpay = new Razorpay(options);
              rzpay.on("payment.failed", function (response) {
                console.log("failed",response)
              });
              rzpay.open();
            }else if(res.data.data.payment_app == 'paypal'){
              console.log(res.data.data.redirectURL)
              window.location.href= res.data.data.redirectURL;
            }  
          } 
          else
            toast.error(res.data.message)
            console.log(res.status,res.data)
            
        }catch(e){
          console.log("Error:",e)
          context.setView({...context.view,loading:false})
          toast.error(e.message)
        }
        //toast.success("Request submitted sucessfully")
      }
    }
    function validateForm(part){
      if(part == 'partOne'){
        if(!fields.last_date_of_period || !fields.first_pregnancy ){
          console.log(fields)
          toast.error("Please Fill Required Fields.")
          return false;
        }
      }
      if(part == 'partTwo'){
        if(validatePersonal(personalDetails) == false){
          console.log(personalDetails);
          toast.error("Please Fill Required Fields.");
          return false
        }
      }
      return true; 
    }
    let bgImage = getConcern(concernType)['bgImage']
    return (
      <section>
        <div className="container mt-5" data-aos="fade-up">
          <div className="section-title">
            <h2>Book Appointment</h2>
          </div>
            <div className="card" style={{backgroundImage:'url('+bgImage+')',backgroundSize:'cover'}}>
              <h5 className="card-header">Pregnancy Related</h5>
              <div className="card-body" style={{background:'#ffffffdb'}}>
              <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                  Is it your 1st Pregnancy? *
                  </label>
                  <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="first_pregnancy"
                        id="firstPregnancyYes"
                        value="yes"
                        onChange={updateField} 
                      />
                      <label
                        className="form-check-label"
                        htmlFor="firstPregnancyYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="first_pregnancy"
                        id="firstPregnancyNo"
                        value="no"
                        onChange={updateField} 
                      />
                      <label
                        className="form-check-label"
                        htmlFor="firstPregnancyNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <LMPField updateField={updateField}/>
                <div className="mb-3 row">
                  <YourComplaintLabel/>
                  <div className="col-sm-9">
                    <FormComplaintBox
                      complaints={complaints}
                      setComplaints={setComplaints}
                    />
                    {partOne == false && (
                      <div className="pricing mt-4">
                        <button type="button" className="buy-btn" onClick={()=>completePartOne()}>Book Appointment</button>
                      </div>
                    )}
                    

                  </div>
                </div>
                {partOne && (<PersonalDetailsBox handlePayButton={submitForm} handleUpdate={updatePersonalField}  details={personalDetails} />)}
              </div>
            </div>
        </div>
      </section>
    );
}

export default PregnancyRelated