import { useContext, useState } from "react";
import FormComplaintBox, {
  complaintTemplate,
} from "../../components/FormComplaintBox";
import { toast } from "react-toastify";
import PersonalDetailsBox, { validatePersonal } from "../../components/PersonalDetailsBox";
import { bookingRequestApi } from "../../network/apiCalls";
import { useLocation } from "react-router-dom";
import { getConcern, getConcernTypeByPath } from "../../utils/ConcernOptions";
import { SettingContext } from "../../App";
import PainScale from "../../components/PainScale";
import { LMPField, YourComplaintLabel, fieldSetter, formDataGenerate } from "../../components/CommonInputs";
import useRazorpay from "react-razorpay";
import { useNavigate } from "react-router-dom"
import { getRazorPaySettings } from "../../utils/PaymentSettings";
const PeriodRelated = () => {
  const context = useContext(SettingContext);
  const location = useLocation();
  const concernType = getConcernTypeByPath(location.pathname.split("/").pop());
  const [partOne, setPartOne] = useState(false);
  const [fields, setFields] = useState({});
  const [personalDetails, setPersonalDetails] = useState({});
  const [complaints, setComplaints] = useState(complaintTemplate);
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const updateField = fieldSetter(fields,setFields)
  const updatePersonalField = fieldSetter(personalDetails,setPersonalDetails)

  function completePartOne() {
    if (validateForm("partOne")) {
      setPartOne(true);
    }
  }
  async function submitForm() {
    if (concernType == false) {
      toast.error("Something wrong! Please reload page and try again");
      return false;
    }
    if (validateForm("partOne") && validateForm("partTwo")) {
      console.log(fields, personalDetails);
      console.log(complaints);
      if (fields.had_period == "no" && fields.last_date_of_period) {
        delete fields.last_date_of_period;
      }
      try {
        let formData = formDataGenerate(fields,personalDetails,complaints,concernType);
        context.setView({ ...context.view, loading: true });
        let res = await bookingRequestApi(formData);
        context.setView({ ...context.view, loading: false });
        if (res.data.status == 200) {
          if(res.data.data.payment_app == 'razorpay'){
            const responseHandler = (paymentResponse)=>{
              toast.success("Payment Successful")
              navigate("/booking-success?id="+res.data.data.booking_code)
            }      
            const options = getRazorPaySettings(responseHandler,{
              name:personalDetails.name,
              contact:personalDetails.phone,
              email:personalDetails.email
            })
            options.order_id = res.data.data.order_id
            console.log("payment settings",options)
            const rzpay = new Razorpay(options);
            rzpay.on("payment.failed", function (response) {
              console.log("failed",response)
            });
            rzpay.open();
          }else if(res.data.data.payment_app == 'paypal'){
            console.log(res.data.data.redirectURL)
            window.location.href= res.data.data.redirectURL;
          }
        } else toast.error(res.data.message);
        console.log(res.status, res.data);
      } catch (e) {
        console.log("Error:", e);
        context.setView({ ...context.view, loading: false });
        toast.error(e.message);
      }
      //toast.success("Request submitted sucessfully")
    }
  }
  function validateForm(part) {
    if (part == "partOne") {
      if (
        !fields.age ||
        !fields.had_period ||
        !fields.regular_period ||
        !fields.painfull_period ||
        !fields.heavy_flow
      ) {
        console.log(fields);
        toast.error("Please Fill Required Fields.");
        return false;
      }
      if (fields.had_period == "yes" && !fields.last_date_of_period) {
        toast.error("Please Fill Required Fields.");
        return false;
      }
      if (fields.painfull_period == "yes" && !fields.pain_scale) {
        toast.error("Please Fill Required Fields.");
        return false;
      }
    }
    if (part == "partTwo") {
      if(validatePersonal(personalDetails) == false){
        console.log(personalDetails);
        toast.error("Please Fill Required Fields.");
        return false
      }
    }
    return true;
  }
  function handleScale(value) {
    updateField({ key: "pain_scale", value: value });
  }
  let bgImage = getConcern(concernType)["bgImage"];
  return (
    <section>
      <div className="container mt-5" data-aos="fade-up">
        <div className="section-title">
          <h2>Book Appointment</h2>
        </div>
        <div
          className="card"
          style={{
            backgroundImage: "url(" + bgImage + ")",
            backgroundSize: "cover",
          }}
        >
          <h5 className="card-header">Period Related</h5>
          <div className="card-body" style={{ background: "#ffffffdb" }}>
            <div className="mb-3 row">
              <label className="col-sm-3 col-form-label">
                What's your Age? *
              </label>
              <div className="col-sm-9">
                <div className="input-group mb-3">
                  <input
                    type="number"
                    name="age"
                    className="form-control"
                    placeholder="Year(s)"
                    onChange={updateField}
                  />
                  <span className="input-group-text">Year(s)</span>
                </div>
              </div>
            </div>
            <div className="mb-3 row">
              <label className="col-sm-3 col-form-label">
                Have you ever had your period? *
              </label>
              <div className="col-sm-9">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="had_period"
                    id="had_periodYes"
                    value="yes"
                    onChange={updateField}
                  />
                  <label className="form-check-label" htmlFor="had_periodYes">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="had_period"
                    id="had_periodNo"
                    value="no"
                    onChange={updateField}
                  />
                  <label className="form-check-label" htmlFor="had_periodNo">
                    No
                  </label>
                </div>
              </div>
            </div>
            {fields?.had_period && fields.had_period == "yes" && (
              <>
                <LMPField updateField={updateField} />
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Is your period regular? *
                  </label>
                  <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="regular_period"
                        id="regular_periodYes"
                        value="yes"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="regular_periodYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="regular_period"
                        id="regular_periodNo"
                        value="no"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="regular_periodNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Is your period painful? *
                  </label>
                  <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="painfull_period"
                        id="painfull_periodYes"
                        value="yes"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="painfull_periodYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="painfull_period"
                        id="painfull_periodNo"
                        value="no"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="painfull_periodNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {fields.painfull_period == "yes" && (
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Explain your pain on scale? *
                    </label>
                    <div className="col-sm-9">
                      <PainScale
                        handleScale={handleScale}
                        painValue={fields.pain_scale}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Are you experiencing heavy flow? *
                  </label>
                  <div className="col-sm-9">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="heavy_flow"
                        id="heavy_flowYes"
                        value="yes"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="heavy_flowYes"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="heavy_flow"
                        id="heavy_flowNo"
                        value="no"
                        onChange={updateField}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="heavy_flowNo"
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="mb-3 row">
              <YourComplaintLabel />
              <div className="col-sm-9">
                <FormComplaintBox
                  complaints={complaints}
                  setComplaints={setComplaints}
                />
                {partOne == false && (
                  <div className="pricing mt-4">
                    <button
                      type="button"
                      className="buy-btn"
                      onClick={() => completePartOne()}
                    >
                      Book Appointment
                    </button>
                  </div>
                )}
              </div>
            </div>
            {partOne && (
              <PersonalDetailsBox
                handlePayButton={submitForm}
                handleUpdate={updatePersonalField}
                details={personalDetails}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PeriodRelated;
