import axios from "axios";
export const BASEURL = process.env.REACT_APP_API_ENDPOINT;
const APIURL = BASEURL + '/api';
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
function getAuthToken() {
  if (localStorage.getItem("authState")) {
    try {
      let token = JSON.parse(localStorage.getItem("authState")).userToken;
      return token;
    } catch (err) {
      console.log("Error:", err);
      return "";
    }
  }
  return "";
}

function commonHeaders(){
  return {
    Authorization: getAuthToken(),
    "Content-Type":"multipart/form-data",
    "Client":'web'
  }
}

export const bookingRequestApi = async (data) => {
  let resposne = await axios({
    method: "post",
    url: APIURL + "/user/booking_request",
    data:data,
  });
  return resposne;
};

export const getMediaApi = async (type) => {
  let resposne = await axios({
    method: "get",
    url: APIURL + "/user/get_media?type="+type,
  });
  return resposne;
};

export const getTestimonialsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: APIURL + "/user/get_testimonials",
  });
  return resposne;
};
export const getSettingsApi = async () => {
  let resposne = await axios({
    method: "get",
    url: APIURL + "/user/get_settings",
  });
  return resposne;
};
export const getAppointmentTokenApi = async (booking_id) => {
  let resposne = await axios({
    method: "post",
    data:{booking_id:booking_id},
    url: APIURL + "/auth/validate_appointment",
  });
  return resposne;
};
export const getAppointmentDetailsApi = async (booking_code) => {
  let resposne = await axios({
    url: APIURL + "/user/appointment_details/"+booking_code,
  });
  return resposne;
};