const FAQSection = () => {
    return (
        <>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
             
            </div>

            <div className="faq-list">
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#faq-list-1"
                  >
                    What changes do I expect in my body during pregnancy ?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-1"
                    className="collapse show"
                    data-bs-parent=".faq-list"
                  >
                    <p> A women undergoes multitude of changes during pregnancy. Certain changes like hyperpigmentation over cheeks,nipples ,abdomen,increase size of breast and abdomen,increased frequency of urination are common during pregnancy. But some can be pathological like undue increase in body weight,pedal edema,facial puffiness,itching specially in palms and soles
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-2"
                    className="collapsed"
                  >
                    Can I travel during Pregnancy ?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-2"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>Unless You have been identified with some complications, it is considered safe to travel during 2nd trimester. One should avoid travelling in 1st and 3rd trimester as much as possible. While traveling you should keep certain things in mind like booking Aisle seat in flights or travelling in train allows easy mobility. You must keep drinking  plenty of water during the journey & keep walking once in every hour and avoid travelling for more than 4 hrs.
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-3"
                    className="collapsed"
                  >
                    What my diet should include during pregnancy?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-3"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    A Pregnant Women needs extra Protein and Fats along with Micronutrients for fulfilling the need of growing baby and her own metabolism. Her diet should include green leafy vegetables,seasonal fruits (instead of packed juices), grains, dairy products, meat. Avoid canned, preserved or junk food.Cut down extra sugar and salt intake.                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-4"
                    className="collapsed"
                  >
                    Is back pain common following cesarean section ? <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-4"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Many women complaints of Back Pain following Spinal Anaesthesia. But studies has shown chronic back pain is common in women who donot take adequate Calcium and Vitamin D supplements during and after  the pregnancy.However,spinal anesthesia has no direct role for it's causation.                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-5"
                    className="collapsed"
                  >
                    I had previous cesarean section, Can I have normal Vaginal Delivery this time?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-5"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Yes,Vaginal delivery is possible in women with history of previous cesarean section. But there are certain parameters that should be checked before allowing vaginal delivery in such pregnant women. You must consult an expert Gynaecologist for the same.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-6"
                    className="collapsed"
                  >
                   What is PCOD? Is it treatable? {" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-6"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>PCOD/PCOS stands for Polycystic Ovarian Disease / Syndrome. It a Syndrome resulting from Hormonal imbalance like Insulin resistance. A female with PCOD may experience irregular periods, androgenic features like hair loss,acne,male pattern of  body hair and polycystic ovaries,which may be seen in USG. Such women are at higher risk of developing diabetes in future.PCOD is a lifestyle disorder & so it can be treated to a great extent by proper lifestyle changes.Hormonal pills are also used for its treatment.
                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-7"
                    className="collapsed"
                  >
                    What are the side effects of hormonal pills?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-7"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Hormonal pills are generally safe with some minor side effects like acne,nausea,weight gain etc.One must take care of contraindications while taking hormonal pills like hypertension,history of stroke,heart attack,thrombotic events,breast cancer. So,hormonal pills must always be taken under supervision. </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-8"
                    className="collapsed"
                  >
                     I am having excessive White Discharge . What shall I do? {" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-8"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Some amount of white discharge during specific period of the menstrual cycle is normal. Any discharge,if excessive in amount or associated with foul smell, pain abdomen, burning or itching sensation is abnormal. One must always consult and get examined by a gynaecologist to know the exact cause and get treated accordingly.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-9"
                    className="collapsed"
                  >
                     What is the safest time to have sexual intercourse with reference to menstrual cycle?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-9"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    A week prior to and following the date of ovulation is considered as unsafe time for having sexual intercourse. As every women has different menstrual cycle pattern, the time of ovulation varies.So, Unprotected Sexual Intercourse isn't advisable if she is not planning to conceive. Consult us to know what is the relatively safest time for you to have sexual intercourse according to your cycle.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-10"
                    className="collapsed"
                  >
                   Is all Ovarian Cyst Cancerous? {" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-10"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Not all ovarian cysts are cancerous. Some like follicular cyst, hemorrhagic cyst, endometriotic cyst, dermoid cyst are usually benign. However,it is advised to undergo clinical examination and evaluation to rule out malignancy.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-11"
                    className="collapsed"
                  >
                     I am having scanty bleeding during my menstrual period. Is it normal?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-11"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Scanty periods can be normal for some but pathological for others. One should be evaluated for hormonal profiles with detailed history and examination,wherever applicable. You should always consult an Expert Gynaecologist for proper evaluation and management of the same.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-12"
                    className="collapsed"
                  >
                    I am having burning burning sensation while passing urine, what shall I do?{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-12"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Burning sensation while passing urine ,increased frequency of urination, lower abdominal pain are some of the symptoms of Urinary Tract Infections(UTI).One should drink plenty of water and get urine evaluation done for various parameters to rule out frank infection. However, a negative report doesnot exclude UTI.                    </p>
                  </div>
                </li>
                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#faq-list-13"
                    className="collapsed"
                  >
                     Is Emergency Contraceptive Tablets safe?  {" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="faq-list-13"
                    className="collapse"
                    data-bs-parent=".faq-list"
                  >
                    <p>
                    Unless You have been identified with some major disease, using Emergency Contraceptive Pill(One such is I-Pill) is  considered to be Safe. Remember these pills must be used only in an emergency situation and must not be misused for every episode of unprotected sexual intercourse.                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </section>
        </>
    )
}

export default FAQSection