import { AudioRecorder } from 'react-audio-voice-recorder';
export const complaintTemplate = {
  photos:[],
  reports:[],
  text:"",
  audioClip:null
}
const FormComplaintBox = ({complaints,setComplaints}) => {
    const handleAudioStop = (blob) => {
        let url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.id = 'recordingID';
        audio.controls = true;
        document.getElementById('recordingPlayerBox').appendChild(audio);
        // setAudioBlob(blob)      
        setComplaints({...complaints,audioClip:blob})
    }
    const removeClip = () => {
        let player = document.getElementById('recordingID');
        if(player)
            player.remove()
            setComplaints({...complaints,audioClip:null})    
    }
    return (
    <>
    <div className="accordion" id="accordionPanelsStayOpenExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#addPhotos" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Add Photos
      </button>
    </h2>
    <div id="addPhotos" className="accordion-collapse collapse show">
      <div className="accordion-body">
        <input type="file" name="photos" className="form-control" multiple onChange={(e)=>setComplaints({...complaints,photos:e.target.files})}/>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#addAudioClip" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
        Add Audio Clip
      </button>
    </h2>
    <div id="addAudioClip" className="accordion-collapse collapse">
      <div className="accordion-body">
        <div className={complaints.audioClip?'d-none':''}>
          <AudioRecorder 
              onRecordingComplete={handleAudioStop}
              audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
              }} 
              downloadOnSavePress={false}
              showVisualizer={true}
          />
        </div>
        {complaints.audioClip? <p>Recording saved and attached</p> :null}
        <div className={'d-flex flex-wrap ' + (complaints.audioClip ? '':'d-none')}>
            <div id="recordingPlayerBox"></div>
            <button type='button' className='btn btn-danger ms-3' onClick={removeClip}>Delete Recording<i className="ri-delete-bin-5-fill"></i></button>
        </div>
                 
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#addReports" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
        Add Reports
      </button>
    </h2>
    <div id="addReports" className="accordion-collapse collapse">
      <div className="accordion-body">
        <input type="file" name="reports" className="form-control" multiple onChange={(e)=>setComplaints({...complaints,reports:e.target.files})}/>
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#addText" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
        Add Text
      </button>
    </h2>
    <div id="addText" className="accordion-collapse collapse">
      <div className="accordion-body">
      <div className="form-floating">
        <textarea className="form-control" id="floatingTextarea2" style={{height:100}} onChange={(e)=>setComplaints({...complaints,text:e.target.value})}></textarea>
        <label htmlFor="floatingTextarea2">Write your concern</label>
        </div>
      </div>
    </div>
  </div>
</div>
    </>
    )
}

export default FormComplaintBox