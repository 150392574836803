export const YourComplaintLabel = () =>{
    return (<>
        <label className="col-sm-3 col-form-label">
            What's your complaint?
            <br/><small>(Explain your complaint by adding Photos/Audio clip/Reports/Text)</small>
        </label>
    </>)
}

export const LMPField =({updateField}) =>{
    return (
        <>
        <div className="mb-3 row">
            <label className="col-sm-3 col-form-label">
            1st Date of Last Menstrual Period(LMP)*
            </label>
            <div className="col-sm-9">
            <div className="input-group flex-nowrap">
                <label htmlFor="last_date_of_period" className="input-group-text" id="addon-wrapping"><i className="ri-calendar-2-fill"></i></label>
                <input id="last_date_of_period" type="date" name="last_date_of_period" className="form-control" onChange={updateField} />
            </div>
            </div>
        </div>
        </>
    )
}


export const fieldSetter = (fields,setFields) => {
    return function (e){
        if(e.nativeEvent){
          //console.log({[e.target.name]:e.target.value})
          setFields({...fields,[e.target.name]:e.target.value})
        }else{
          //console.log(e)
          setFields({...fields,[e.key]:e.value})
        }
      }
}

export const formDataGenerate = (fields,personalDetails,complaints,concernType)=>{
    let formData = new FormData()
    formData.set('form_fields',JSON.stringify(fields))
    formData.set('concern_type',concernType)
    for(let keyName of Object.keys(personalDetails)){
    formData.set(keyName,personalDetails[keyName])
    }
    if(complaints == null) {
        return formData;
    }
    let photoIndex = 0;
    for(let photo of complaints.photos){
        formData.set(`photos[${photoIndex++}]`,photo,photo.name)
    }
    let reportIndex = 0;
    for(let report of complaints.reports){
        formData.set(`reports[${reportIndex++}]`,report,report.name)
    }
    console.log(complaints.audioClip)
    if(complaints.audioClip){
    let audioName = `Recording-${Math.floor(Math.random() * 10000)}.webm`;
    formData.set("audio_clip",complaints.audioClip,audioName)
    }
    formData.set("concern_text",complaints.text)
    return formData
}