const PageNotFound = () => {
    return(
        <section>
            <div className="pt-5">
                <div className="text-center mt-5">
                    <h1 className="display-1 mt-5 fw-bold">404</h1>
                    <h1 className="display-5">Page Not Found</h1>
                </div>
            </div>
        </section>
        
    )
}
export default PageNotFound