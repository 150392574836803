const RefundPolicy = () => {
  return (
    <section className="mt-5">
      <div className="container watermark">
        <div className="row">
          <div className="col-12 policy-section">
            <h2 className="policy-header">
              Refund Policy (for Online Consultations)
            </h2>
            <div className="policy-section">
              <h5 className="policy-header">Eligibility for Refunds</h5>
              <ul className="policy-content">
                <li>
                  Refund requests must be made within 48 hours of the
                  consultation.
                </li>
                <li>
                  Refunds may be granted for unsatisfactory service,
                  non-delivery of service or technical issues.
                </li>
                <li>
                  Request raised will be assessed by our internal team before
                  processing the refund.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Non-refundable Situations</h5>
              <ul className="policy-content">
                <li>
                  Completed & satisfactory consultations are not eligible for a
                  refund.
                </li>
                <li>
                  Missed or cancelled appointments without at least 24 hours
                  notice are non-refundable.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Refund Request Process</h5>
              <ul className="policy-content">
                <li>
                  To initiate a refund request, contact our customer service at{" "}
                  <a href="mailto:maatriupchaar.dgkhc@gmail.com">
                    maatriupchaar.dgkhc@gmail.com
                  </a>
                </li>
                <li>
                  Provide your Appointment ID, date of booked appointment and
                  reason for the refund request.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Processing Time</h5>
              <ul className="policy-content">
                <li>
                  Refund requests are reviewed and processed within 3-5 business
                  days.
                </li>
                <li>Refunds will be issued to the original payment method.</li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Exceptions and Special Cases</h5>
              <p className="policy-content">
                For misdiagnosis or inadequate service, please contact us
                immediately at{" "}
                <a href="mailto:maatriupchaar.dgkhc@gmail.com">
                  maatriupchaar.dgkhc@gmail.com
                </a>{" "}
                with your Appointment ID and the issue related with it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default RefundPolicy;
