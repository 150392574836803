const PrivacyPolicy = () => {
  return (
    <section className="mt-5">
      <div className="container watermark">
        <div className="row">
          <div className="col-12 policy-section">
            <h2 className="policy-header">Privacy Policy</h2>
            <div className="policy-section">
              <h5 className="policy-header">Introduction</h5>
              <p className="policy-content">
                Welcome to Maatri Upchaar-The Women's Clinic. We are committed
                to protecting your privacy and ensuring the security of your
                personal information. This Privacy Policy outlines how we
                collect, use, disclose and protect your information when you
                use our services.
              </p>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Information We Collect</h5>
              <p className="policy-content">
                We collect various types of information to provide and improve
                our services including:
              </p>
              <h5 className="policy-header">Personal Information</h5>
              <ul className="policy-content">
                <li>Contact Information: Name, email address, phone number.</li>
                <li>
                  Health Information: Medical history including sensitive
                  personal information, current health status, and other
                  relevant health information.
                </li>
                <li>
                  Payment Information: UPI address, Credit/Debit Card details
                  (as per your choice in order to process the payment) and
                  billing information.
                </li>
              </ul>
              <h5 className="policy-header">Non-Personal Information</h5>
              <ul className="policy-content">
                <li>
                  Usage Data: Information about how you use our website and
                  services, including IP address, browser type and operating
                  system.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">How We Use Your Information</h5>
              <p className="policy-content">
                We use the information we collect for various purposes
                including:
              </p>
              <ul className="policy-content">
                <li>
                  To Provide Services: Providing access to right knowledge,
                  diagnosis and treatment with wide limitations with respect to
                  online mode of Consultation.
                </li>
                <li>To Process Transactions: Managing billing and payments.</li>
                <li>
                  To Improve Our Services: Analyzing usage data to enhance user
                  experience.
                </li>
                <li>
                  To Communicate: Sending notifications, updates and
                  promotional materials.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Sharing Your Information</h5>
              <p className="policy-content">
              We do not sell, trade or otherwise transfer your personal information to outside parties except in the following circumstances:
              </p>
              <ul className="policy-content">
                <li>
                  With Your Consent: We may share your information with third
                  parties if you provide explicit consent.
                </li>
                <li>
                  Service Providers: We may share information with trusted
                  service providers who assist us in operating our website and
                  services, provided they agree to keep this information
                  confidential.
                </li>
                <li>
                  Legal Requirements: We may disclose your information if
                  required by a person/department authorized in any law, that is
                  currently in force.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Data Security</h5>
              <p className="policy-content">
                We implement a variety of security measures to protect your
                personal information, including:
              </p>
              <ul className="policy-content">
                <li>
                  Encryption: Protecting sensitive information during
                  transmission.
                </li>
                <li>
                  Access Controls: Limiting access to your information to
                  authorized personnel.
                </li>
                <li>
                  Regular Audits: Conducting regular security audits and
                  assessments.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Your Rights</h5>
              <p className="policy-content">
                You have certain rights regarding your personal information,
                including:
              </p>
              <ul className="policy-content">
                <li>
                  Access: The right to access the personal information we hold
                  about you.
                </li>
                <li>
                  Correction: The right to correct any inaccuracy in your
                  information.
                </li>
                <li>
                  Deletion: The right to request the deletion of your personal
                  information.
                </li>
                <li>
                  Objection: The right to object to the processing of your
                  information under certain circumstances.
                </li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Cookies</h5>
              <p className="policy-content">
                We use cookies and similar technologies to enhance your
                experience on our website. You can manage your cookie
                preferences through your browser settings.
              </p>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Changes to This Privacy Policy</h5>
              <p className="policy-content">
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                our website. Your continued use of our services after any
                changes indicates your acceptance of the updated policy.
              </p>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Contact Us</h5>
              <p className="policy-content">
                If you have any questions about this Privacy Policy or our data
                practices, please contact us at:
              </p>
              <ul className="policy-content">
                <li>Maatri Upchaar (Unit of DGK Healthcare Pvt Ltd)</li>
                <li>
                  Address: 2nd Floor, M Square, Thana Road, Sitamarhi-843302
                </li>
                <li>
                  Email:{" "}
                  <a href="mailto:maatriupchaar.dgkhc@gmail.com">
                    maatriupchaar.dgkhc@gmail.com
                  </a>
                </li>
                <li>Phone number: 9229695061</li>
              </ul>
            </div>
            <div className="policy-section">
              <h5 className="policy-header">Effective Date</h5>
              <p className="policy-content">
                This Privacy Policy is effective from 01st July, 2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PrivacyPolicy;
