import { useLocation,useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

const BookingSuccess  = () =>{
    const [params,setParams] = useSearchParams()
    const navigate = useNavigate()
    if(params.get('id') == null){
        toast.error("Invalid details")
        navigate("/")
        return false;
    }
    return (<>
         <section>
            <div className="pt-5">
                <div className="text-center mt-5">
                    <h1 className="display-1 mt-5 fw-bold text-success">Appointment Scheduled</h1>
                    <h1 className="display-5">Your appointment id is "<strong>{params.get('id') ?? ''}</strong>".</h1>
                    <h1 className="display-5">Appointment details has been sent on your email.</h1>
                </div>
            </div>
        </section>
    </>)
}
export default BookingSuccess