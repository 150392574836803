const TermsAndConditions = () => {
  return (
    <section className="mt-5">
      <div className="container watermark">
        <div className="row">
          <div className="col-12 terms-section">
            <h2 className="terms-header">Terms & Conditions</h2>
            <div className="terms-section">
              <h5 className="terms-header">Introduction</h5>
              <p className="terms-content">
                Welcome to Maatri Upchaar-The Women's Clinic. By using our
                services, you agree to comply with and be bound by the following
                Terms and Conditions. Please carefully go through these terms
                and conditions before you decide to access this website or avail
                the services made available on the website by Maatri Upchaar.
                These terms and privacy policy together constitute a legal
                agreement between you and Maatri Upchaar in connection with your
                visit and use of our services.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Acceptance of Terms</h5>
              <p className="terms-content">
                By accessing and using our online services, you acknowledge that
                you have read, understood and agree to be bound by these Terms
                and Conditions, as well as our Privacy Policy. We reserve the
                right to modify or terminate any portion of the Agreement for
                any reason at any time, and such modifications shall be informed
                to you in writing. You should read the Agreement at regular
                intervals. Your use of the website following any such
                modifications constitute your agreement to follow and be bound
                by the Agreement so modified.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Services Provided</h5>
              <p className="terms-content">
                Maatri Upchaar offers online gynecological consultancy services,
                including but not limited to medical advice, consultations and
                recommendations. Our services are intended for informational
                purposes along with Professional medical advice, limited
                diagnosis and treatment. Being an online platform, we have the
                limitation of not being able to do physical examination of our
                patients, which in some cases will not allow us to reach upto
                the final diagnosis and offer the treatment. The services are
                not intended to be a substitute for getting emergency
                healthcare. If you are an End-User, facing a medical emergency
                (either on your or another person's behalf), please contact a
                nearby healthcare facility directly.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">User Responsibilities</h5>
              <p className="terms-content">
                When using our services, you agree to:
              </p>
              <ul className="terms-content">
                <li>
                  Provide accurate and complete information about your health
                  and medical history.
                </li>
                <li>
                  Follow the advice and recommendations provided by our
                  consultants.
                </li>
                <li>Use our services for lawful purposes only.</li>
                <li>
                  If a user provides any information that is untrue, inaccurate,
                  not current or incomplete, then Maatri Upchaar has reasonable
                  ground to suspect that such information is untrue, inaccurate,
                  not current or incomplete, Maatri Upchaar has the right to
                  discontinue the services to the user at its own discretion.
                </li>
              </ul>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Eligibility</h5>
              <p className="terms-content">To use our services, you must:</p>
              <ul className="terms-content">
                <li>
                  Be at least 18 years of age or above. By registering, visiting
                  and using the Website or accepting this Agreement, you
                  represent and warrant to Maatri Upchaar that you are 18 years
                  of age or older and that you have the right, authority and
                  capacity to use the website and the services available through
                  the website and agree to abide by this agreement.
                </li>
                <li>
                  If you are below 18 years of age, you can only use our
                  services for informational and educational purposes, but we
                  would need the consent of your parent or legal guardian for
                  any sort of treatment.
                </li>
                <li>
                  Agree to provide accurate, current, and complete information
                  during the registration process.
                </li>
              </ul>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Consultation and Advice</h5>
              <p className="terms-content">
                The health expert is licensed professional in the field of
                Gynecology and Obstetrics and she uses her medical expertise to
                provide advice based on the information you provide. However, we
                do not guarantee specific outcomes or results. Always seek a
                second opinion or in-person consultation for critical health
                issues or for conditions that requires physical examination.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Payment and Fees</h5>
              <p className="terms-content">
                All services are subject to fees as outlined on our website.
                Payments must be made at the time of booking a consultation. We
                accept various payment methods as specified on our website.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Cancellations and Refunds</h5>
              <p className="terms-content">
                Cancellation: You may cancel your appointment up to 24 hours
                before the scheduled time for a full refund. Refund: Refund will
                be processed within three business days, if applicable. In case
                of Patient-No-Show (P.N.S) where the patient who has booked an
                appointment has not turned up for the same, has to write to us :
                maatriupchaar.dgkhc@gmail.com within 12hrs with a valid reason
                for P.N.S, if Maatri Upchaar finds the reason valid, we will
                reschedule the appointment which will be subjected to
                availability. However, the Users should understand that, these
                actions are included as a deterrent to stop Users from misusing
                our Website, and the loss of business hours that follows.
                Users will not be entitled for any refund in cases where, the
                expert Gynecologist is unable to meet the User at the exact
                time of the scheduled appointment and the User is required to
                wait, irrespective of the fact whether the user is required to
                wait or choose to not obtain the medical services from us. In
                the event that the expert Gynecologist has not been able to
                meet the User, then the User will need to write to us at:
                maatriupchaar.dgkhc@gmail.com within 48hrs from occurrence of
                such event, in which the entire fee will be refunded to the User
                within three business days.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Confidentiality and Privacy</h5>
              <p className="terms-content">
                We are committed to protect your privacy and maintain the
                confidentiality of your personal and medical information. Please
                refer to our Privacy Policy for more details.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Limitation of Liability</h5>
              <p className="terms-content">
                Maatri Upchaar and its expert Gynecologist are not liable for
                any direct, indirect, incidental or consequential damages
                arising from the use of our services. We do not guarantee that
                our services will be uninterrupted or error-free.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Intellectual Property</h5>
              <p className="terms-content">
                All content on our website, including text, graphics, logos, and
                images, is the property of Maatri Upchaar and is protected by
                copyright and trademark laws. You may not use, reproduce or
                distribute any content without our written permission. Users are
                also prohibited from:
              </p>
              <ul className="terms-content">
                <li>
                  i. Violating or attempting to violate the integrity or
                  security of the Website or its contents.
                </li>
                <li>
                  ii. Transmitting any information (including posts, images,
                  links etc) on or through our Website that is disruptive or
                  competitive to the provision of services provided by us.
                </li>
                <li>
                  iii. Intentionally submitting on the website any incomplete or
                  inaccurate information.
                </li>
                <li>
                  iv. Using any engine, software, tool, agent or other
                  device/mechanism (such as spiders,robots,avatars or
                  intelligent agents) to navigate or search the Website.
                </li>
                <li>
                  v. Making any unsolicited communication to the healthcare
                  expert.
                </li>
              </ul>
              <p className="terms-content">
                Please note that some of the content, data, text, graphics,
                images, information, suggestions or other material may be
                available in the website are only for informational purposes
                which may be provided by individuals in medical profession, and
                are not meant to hurt any cultural or religious belief.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Termination</h5>
              <p className="terms-content">
                We reserve the right to terminate or suspend your access to our
                services at any time, without notice, for conduct that we
                believe violates these Terms and Conditions or is harmful to
                other users of our services.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Governing Law</h5>
              <p className="terms-content">
                These Terms and Conditions are governed by and construed in
                accordance with the laws of India. Any disputes arising
                from these terms will be subject to the exclusive jurisdiction
                of the courts of Sitamarhi, India. The Agreement is published in
                compliance of, and is governed by the provisions of Indian law,
                including but not limited to:
              </p>
              <ul className="terms-content">
                <li>i. The Indian Contract Act 1872,</li>
                <li>ii. The(Indian) Information Technology Act 2000, and</li>
                <li>
                  iii. The rules, regulations, guidelines and clarifications
                  framed there under, including the (Indian) Information
                  Technology (Reasonable Security Practices and Procedures and
                  Sensitive Personal Information) Rules ,2011(the “SPI Rules”)
                  and Information Technology (Intermediary Guidelines and
                  Digital Ethics Code)Rules, 2021(the “IG Rules”).
                </li>
                <li>iv. Jurisdiction: Sitamarhi district court</li>
              </ul>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Changes to Terms and Conditions</h5>
              <p className="terms-content">
                We reserve the right to modify these Terms and Conditions at any
                time. Any changes will be effective immediately upon posting on
                our website. Your continued use of our services constitutes your
                acceptance of the revised terms.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Retention and Removal</h5>
              <p className="terms-content">
                Maatri Upchaar may retain such information collected from the
                users provided in the website as long as necessary, depending on
                the type of the information; purposes, means and modes of usage
                of such information are in accordance with the SPI Rules.
              </p>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Contact Us</h5>
              <p className="terms-content">
                If you have any questions or concerns about these Terms and
                Conditions, please contact us at:
              </p>
              <ul className="terms-content">
                <li>Maatri Upchaar(Unit of DGK Healthcare Pvt Ltd)</li>
                <li>
                  Address: 2nd Floor, M Square, Thana Road, Sitamarhi-843302
                </li>
                <li>Email: maatriupchaar.dgkhc@gmail.com</li>
                <li>Phone number: 9229695061</li>
              </ul>
            </div>
            <div className="terms-section">
              <h5 className="terms-header">Effective Date</h5>
              <p className="terms-content">
                These Terms and Conditions are effective as of 01/07/2024.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TermsAndConditions;
