
import './App.css';
import MainSite from './pages/MainSite';
import { useEffect ,createContext, useState} from 'react';
import {BrowserRouter} from 'react-router-dom'
import { getSettingsApi } from './network/apiCalls';
export const SettingContext = createContext(null)
function App() {
  const [appSettings,setAppSettings] = useState({quote:'',quote_author:'',fees:0})
  const [viewSettings,setViewSettings] = useState({loading:false})
  useEffect(()=>{
    fetchSettings()
  },[]);

    async function fetchSettings(){
      try{
        let res = await getSettingsApi()
        console.log(res.data.data)
        setAppSettings(res.data.data)
      }catch(e){
        console.log(e.message)
      }
    }
  return (
    <BrowserRouter>
    <SettingContext.Provider value={{settings:appSettings,view:viewSettings,setView:setViewSettings}}>
      <MainSite/>
    </SettingContext.Provider>
    </BrowserRouter>
    
  );
}

export default App;
