import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Header  = () => {
    const [language,setLanguage] = useState('en');
    const langObj = {"en":"English","hi":"हिंदी","bn":"বাংলা"};
    function changeLanguage(newLanguage) {
      if (newLanguage == "en") {
        window.location.reload();
        return;
      }

      try {
        window.languageTranslator.translatePage("en", newLanguage, function () {
          setLanguage(newLanguage);
        });
      } catch (e) {
        console.log("Failed:", e);
      }
      
      
    }

    return (<>
    
    <header id="header" className="fixed-top " style={{padding:'5px 0px'}}>
        <div className="container d-flex align-items-center">
          <Link to="/" className="logo me-auto d-flex align-items-center" style={{lineHeight:'21px'}}>
          <span className="text-white logo-text" style={{fontWeight:'bolder'}}>
            <img src={'/assets/img/logo.png'} style={{maxHeight:70}}/>
          </span>
          <div className="d-flex flex-column">
            <span style={{fontSize:18,color:'white',marginLeft:10,fontWeight:'bold',textTransform:'none',fontFamily:'DM Serif Text'}}>Maatri Upchaar</span>
            <span style={{fontSize:12,color:'white',marginLeft:10}}>The women's clinic</span>
          </div>
            
          </Link>
          <nav id="navbar" className="navbar">
            
          </nav>
          <div id="google_translate_element"></div>
            <div className="btn-group" role="group">
              <a id="btnGroupDrop1" type="button" className="btn dropdown-toggle getstarted scrollto" data-bs-toggle="dropdown" aria-expanded="false">
                {langObj[language] ?? 'Unknown'}
              </a>
              <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
               {Object.keys(langObj).map((code,key)=>{
                  return <li key={key}><a className="dropdown-item" onClick={()=>changeLanguage(code)} translate="no">{langObj[code]}</a></li>
               })}
              </ul>
            </div>
        </div>
      </header>
    </>)
}

export default Header