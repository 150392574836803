const HeroBanner = () => {
    return (
        <>
            <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h1>Dr. Ayushi Agrawal</h1>
              <h2>
                MBBS (Hons. Gold Medal)
                <br />
                MS (Gold Medal), DNB
                <br />
                Consultant Gynaecologist
                <br />& Obstetrician
              </h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="#services" className="btn-get-started scrollto">Book Appointment</a>
            </div>
            </div>

            <div
              className="col-lg-6 order-1 order-lg-2 hero-img text-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <img
                src="assets/img/doctor.png"
                className="img-fluid animated doctor-img"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
        </>
    )
}

export default HeroBanner