import { useParams } from "react-router-dom";
import { getMedia } from "../utils/MediaOptions";
import { useEffect, useState } from "react";
import { fetchUserApi, getMediaApi } from "../network/apiCalls";
const MediaPage = () => {
  const [list,setList] = useState([])
  useEffect(() => {
    fetchMedia(type)
  }, []);
  
  const { type } = useParams();
  const media = getMedia(type);

  async function fetchMedia(key){
    console.log("fetching:",type)
    try{
      let res = await getMediaApi(key)
      setList(res.data.data)
    }catch(e){
      console.log(e.message)
    }
  }

  return (
    <>
      <section id="portfolio" className="portfolio">
        <div className="container mt-5" data-aos="fade-up">
          <div className="section-title">
            <h2>{media.title}</h2>
          </div>

          <div
            className="row"
          >
            {list.map((item, key) => {
              let type = getMedia(item.type)
              if(type.type =='image')
                return  <MediaImage key={key} item={item}/>;
              else
                return  <MediaVideo key={key} item={item}/>;
            })}
          </div>
        </div>
      </section>
    </>
  );
};
export default MediaPage;

const MediaImage = ({item}) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="" style={{height:250, textAlign:"center"}}>
        <img
          src={item.image}
          style={{height:'100%',width:'100%',objectFit:'cover',border:"2px solid #c02c73",borderBottom:0}}
          onClick={()=>window.location.href=item.image}
        />
      </div>
        <p className="bg-pink p-2">{item.title}</p>
    </div>
  );
};

const MediaVideo = ({item}) => {
    return (

      <div className="col-lg-4 col-md-6">
      <div className="" style={{height:250, textAlign:"center"}}>
      <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/"+item.video_url+"?si=s4AvZMpiOtkugiKB&amp;controls=0"} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>
        <p className="bg-pink p-2">{item.title}</p>
    </div>
    );
  };
