import { Link } from "react-router-dom";
import { mediaOptions } from "../utils/MediaOptions";
const GalleryItems = () => {
    let items  = mediaOptions
    items["testimonials"] = {title:"Testimonials"}
    
  return (
    <>
      <section id="services" className="services">
        <div className="row pricing p-3">
            {Object.keys(items).map((index,key)=>{
                return(
                    <div key={key} className="col-12 col-sm-6 col-md-3 text-center my-2">
                        <Link to={(index == 'testimonials'?'':'/media/') + index} className="buy-btn  w-100">
                            {items[index].title}
                        </Link>
                  </div>
                )
            })}
          
        </div>
      </section>
    </>
  );
};

export default GalleryItems;
