import { useContext } from "react"
import { SettingContext } from "../App"

const IntroSection = () => {
    const {settings} = useContext(SettingContext)
    console.log(settings)
    return (
        <>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Intro</h2>
            </div>
            <div className="row content">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i> A place to address
                    all concerns related to women wellbeing
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i> We help you to take actions at right time and provide you with end to end solutions and follow-up,wherever applicable.
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0">
                <figure className="text-center">
                  <blockquote className="blockquote">
                    <p>
                      <big>"</big>{settings?.quote}.<big>"</big>
                    </p>
                  </blockquote>
                  <figcaption className="blockquote-footer mt-2">
                    <cite title="Source Title">{settings?.quote_author}</cite>
                  </figcaption>
                </figure>
                {/* <a href="#" className="btn-learn-more">Learn More</a> */}
              </div>
            </div>
          </div>
        </section>
        </>
    )
}

export default IntroSection