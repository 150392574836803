import * as React from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { getAppointmentTokenApi } from "../network/apiCalls";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

let boxRef = null;
const AppointmentRoom = ({booking,token}) => {
  const startCall = () => {
   
    if (!token || !booking) {
      toast.error("Token Missing");
      return false;
    }
    const roomID = booking.booking_code;
    const appID = 523161844; //from zego MU_video_and_audio
    let zp = null;
    try{
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForProduction(
        appID,
        token,
        roomID,
        "MUPatient",
        booking.name
      );
      zp = ZegoUIKitPrebuilt.create(kitToken);
    }catch(e){
      toast.error(e.message)
      return false
    }
    
  // start the call
    const config = {
      container: boxRef,
      showTextChat: false,
      showUserList: false,
      showRemoveUserButton: false,
      showPreJoinView: false,
      showRoomDetailsButton: false,
      showLeavingView: false,
      showRoomTimer: true,
      turnOnCameraWhenJoining: booking.mode == 'video' ? true : false,
      showMyCameraToggleButton: booking.mode == 'video' ? true : false,
      showAudioVideoSettingsButton: booking.mode == 'video' ? true : false,
      showScreenSharingButton: false,
      maxUsers: 2,
      scenario: {
        mode: ZegoUIKitPrebuilt.OneONoneCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
      onLeaveRoom: () => {
        zp.destroy();
      },
    };
    try{
        zp.joinRoom(config);
    }catch(e){
      toast.error(e.message)
    }
   
  };
  return (
    <>
      <div ref={boxRef}></div>
      <div className="text-center">
        <button className="btn btn-primary" onClick={() => startCall()}>Start Call</button>
      </div>
    </>
            
  );
};
export default AppointmentRoom;
