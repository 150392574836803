import * as React from "react";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { getAppointmentDetailsApi, getAppointmentTokenApi } from "../network/apiCalls";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AppointmentChatRoom from "./AppointmentChatRoom";
import AppointmentRoom from "./AppointmentRoom";
import { useSearchParams } from "react-router-dom";

const AppointmentEntry = () => {
  const [params] = useSearchParams();
  const [booking, setBooking] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  async function validateAppointment() {
    let id = document.getElementById("appointmentId").value;
    if(!id){
      toast.error("Please enter appointment ID");
      return false;
    }
    try {
        setLoading(true)
      let res = await getAppointmentTokenApi(id);
      if (res.status == 200 && res.data.status == 200) {
        setBooking(res.data.booking);
        setToken(res.data.authToken);
      } else {
        toast.error(res.data.message);
      }
      setLoading(false)
      console.log("TOken", res.data.authToken);
    } catch (e) {
      console.log("token error", e);
      setLoading(false)
    }
  }
  
  async function appointmentDetails() {
    let id = document.getElementById("appointmentId").value;
    if(!id){
      toast.error("Please enter appointment ID");
      return false;
    }
    try {
        setLoading(true)
      let res = await getAppointmentDetailsApi(id);
      if (res.status == 200 && res.data.status == 200) {
        setBooking(res.data.booking);
        if(res.data.booking.valid == 1){
            setToken(res.data.token)
        }
      } else {
        toast.error(res.data.message);
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  if (booking == null) {
    return (
      <section>
        <div className="row pt-5 justify-content-center">
          <div className="col-lg-8">
           <h1 className="text-center">Validate Your Appointment</h1>
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label style={{fontSize:16}}>Enter Your Booking ID</label>
                  <input id="appointmentId" type="text" className="form-control" placeholder="e.g. MU24XXXXX" defaultValue={params.get('id')}/>
                </div>
              </div>
              <div className="card-footer">
                {loading == true ? (<p>Please Wait...</p>) : (
                    <button className="btn btn-primary" type="button" onClick={appointmentDetails}>Validate</button>
                )}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  let totalSeconds = 0;
  if(booking.valid == 1 && booking.payment_status == 1){
    totalSeconds = parseInt(booking.endTime) - parseInt(booking.nowTime)
    if(totalSeconds > 3){
      console.log("total seconds",totalSeconds)
      let interval = setInterval(()=>{
          totalSeconds--;
          if(totalSeconds == 0){
            clearInterval(interval);
            window.location.reload()
            return false;
          }
          let minutes = parseInt(totalSeconds/60);
          let seconds = totalSeconds%60;
          let leftTime =(minutes < 10 ? ('0'+minutes) : minutes) + ':' +(seconds < 10 ? ('0'+seconds) : seconds);
          document.getElementById("a-timer").innerHTML = leftTime ;  
          // console.log(leftTime)
      },1000);
    }
  }
  
  return (
    <section>
      {totalSeconds > 3 && (
              <div className="appointment-timer p-2 text-center" style={{height:80}}>Appointment Closing in <br/><strong id="a-timer">00:00</strong></div>
      )}
      <div className="row pt-5 justify-content-center">
          <div className="col-lg-8">
            <h1 className="text-center">Appointment Details</h1>
            <table className="table table-bordered table-sm bg-white">
              <tbody>
              <tr>
                <th>Booking ID</th><td>{booking.booking_code}</td>
                <th>Mode</th><td className="text-capitalize">{booking.mode}</td>
              </tr>
              <tr>
                <th>Name</th><td colSpan={3}>{booking.name}</td>
                
              </tr>
              <tr><th>Email</th><td colSpan={3}>{booking.email}</td></tr>
              <tr>
                <th>Date</th><td>{booking.slot_date}</td>
                <th>Time</th><td>{booking.slot_time}:{booking.slot_count}0</td>
              </tr>
              <tr>
                <th>Concern</th><td className="text-capitalize">{booking.concern_type}</td>
                <th>Payment Status</th><td>{booking.payment_status == '0'?'Unpaid':'Paid'}</td>
              </tr>
              </tbody>
            </table>   
            {booking.payment_status == 1 ? (
                (booking.valid == 1 ?
                (booking.mode == 'text' 
                    ? <AppointmentChatRoom booking={booking} token={token} /> 
                    : <AppointmentRoom booking={booking} token={token} />
                )
                :(
                    booking.valid == 0 ? <EarlySlot/> : <MissedSlot/>
                ))
            )
            :(
              <UnpaidOrder/>
            )}
          </div>
      </div>
    </section>
  );
};
export default AppointmentEntry;

const MissedSlot = () => {
    return <p className="text-center">Appointment Closed.</p>
}
const EarlySlot = () => {
    return <p className="text-center">Please wait till your appointment time.</p>
}
const UnpaidOrder = () => {
  return <p className="text-center">Your appointment fee is due.</p>
}