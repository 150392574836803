export const mediaOptions = {
    "pictures": { title: "Picture Gallery", type: "image" },
    "videos": { title: "Video Gallery", type: "video" },
    // "ot-videos": { title: "OT Videos", type: "video" },
    "pictures-2": { title: "Achievements", type: "image" },
    // "filed-visit": { title: "Field Visits", type: "image" },
};
export function getMedia(type){
    return mediaOptions[type]
}
